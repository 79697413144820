// 引入工具函数
import { http, httpimg } from '../../api/index'


// 酒店管理-上报列表
export const reportList = params => http.get('/ohealth/api/v1/epidemicControl/streetreg/reportList', params)

// 酒店管理-列表
export const streetregList = params => http.get('/ohealth/api/v1/epidemicControl/streetreg/list', params)

// 酒店管理-信息
export const getRoleRes = params => http.get('/ohealth/api/v1/epidemicControl/streetreg/info/' + params.id, params)

// 酒店管理-保存
export const streetregSave = params => http.post('/ohealth/api/v1/epidemicControl/streetreg/save', params)

// 酒店管理-修改
export const streetUpdate = params => http.put('/ohealth/api/v1/epidemicControl/streetreg/update', params)

// 酒店管理-删除
export const streetDelete = params => http.delete('/ohealth/api/v1/epidemicControl/streetreg/delete/' + params.id, params)

// 酒店管理-下载
export const exportStreetRegTemplate = params => httpimg.post('/ohealth/api/v1/epidemicControl/streetreg/exportStreetRegTemplate', params)

// 酒店管理-上传
export const uploadStreetRegExcel = params => http.post('/ohealth/api/v1/epidemicControl/streetreg/uploadStreetRegExcel', params)

// 酒店管理-批量居家
export const batchIsolationByHome = params => http.post('/ohealth/api/v1/epidemicControl/streetreg/batchIsolationByHome', params)

// 酒店管理-批量集中
export const batchIsolationByHotel = params => http.post('/ohealth/api/v1/epidemicControl/streetreg/batchIsolationByHotel', params)

